main {
    width: 100%;
    display: flex;
    flex-direction: column
  }
  
  .secao-banner-servicos {
    width: 100%;
    height: 60vh;
    display: flex;
    position: relative;
    
    align-items: center;
    justify-content: center;
    background-image: url('/public/assets/carro.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .titulo-banner-servicos {
    padding: 20px 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  
  .titulo-banner-servicos h1{
    color: aliceblue;
    font-size: 60px;
    text-shadow: 3px 5px 2px #474747;
  }
  
  .secao-servicos {
    width: 100%;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .secao-servicos h2 {
    font-size: 30px;
    font-weight: 600;
  }
  
  .container-servicos {
    padding: 20px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .box-servico {
    width: 30%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 26px;
    border-radius: 4px ;
    box-shadow: 2px 2px 15px 2px #d9dde1;
    margin-bottom: 30px;
  }
  
  .box-servico p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .box-servico img {
    width: 100%;
    height: 250px;
    object-fit: contain;
  }
  
  @media screen and (max-width: 768px) {
    .titulo-banner-servicos h1 {
      font-size: 60px;
    }
  
    .box-servico {
      padding: 20px 40px;
    }
  
    .container-servicos {
      flex-direction: column;
      align-items: center;
    }
  
    .box-servico {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  
  @media screen and (max-width: 425px){
    .titulo-banner-servicos h1 {
      font-size: 45px;
    }
  }
