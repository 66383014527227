
main {
  display: flex;
  flex-direction: column
}

.secao-banner-sobre {
  height: 60vh;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: url('/public/assets/carro.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}

.titulo-banner-sobre {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.titulo-banner-sobre h1{
  color: aliceblue;
  font-size: 60px;
  text-shadow: 3px 5px 2px #474747;
  
}

.container-secao-sobre-nos div {
  padding: 100px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -60px;

  
}

.container-secao-sobre-nos h2{
  font-size: 35px;
  line-height: 56px;
  text-align: center;
  margin-bottom: 0px;
}

.container-secao-sobre-nos span {
  font-size: 22px;
  font-style: italic;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 0px;
  text-align: center;
}

.container-secao-sobre-nos p {
  text-align: center;
  line-height: 26px;
  margin-bottom: 15px;
  font-size: 22px;
}

.container-secao-imagens div {
  display: flex;
}

/* fotos */

.container-secao-imagens img {
  width: 50%;
  height: auto;
  margin-top: -100px;
  object-fit: cover;
  

  }

 
@media screen and (max-width: 768px) {
  .titulo-banner-sobre h1 {
    font-size: 60px;
  }


  .container-secao-imagens div {
    flex-direction: column;
  }

  .container-secao-imagens img {
    width: 100%;
    margin-bottom: 100px;
    
  }


}

@media screen and (max-width: 436px){
  .titulo-banner-sobre h1 {
    font-size: 40px;
  }
  .texto-secao-sobre-nos {

    margin-top: -70px;

  }

  .container-secao-sobre-nos span {

    font-size: 15px;
  }

  .container-secao-sobre-nos  {

   margin-bottom: 10px;

  }



}
