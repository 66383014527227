

.secao-banner {
  width: 100%;
  height: 60vh;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('/public/assets/carro.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #00000046; 
}

.texto-banner {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 100px 30px;
}

.texto-banner h1 {
  font-size: 60px;
  color: aliceblue;
  text-shadow: 3px 5px 2px #474747;
}

.secao-nossos-servicos,
.secao-nossos-clientes,
.secao-sobre-nos {
  display: flex;
}

.secao-nossos-servicos img,
.secao-nossos-clientes img,
.secao-sobre-nos img {
  width: 50%;
  height: auto;
  object-fit: cover;
  margin-bottom: 0px;
}

.texto-secao-nossos-servicos,
.texto-secao-nossos-clientes,
.texto-secao-sobre-nos {
  width: 50%;
  padding: 100px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.texto-secao-nossos-servicos h2,
.texto-secao-nossos-clientes h2,
.texto-secao-sobre-nos h2 {
  font-size: 35px;
  line-height: 36px;
  text-align: center;
}

.texto-secao-nossos-servicos span,
.texto-secao-nossos-clientes span,
.texto-secao-sobre-nos span {
  font-size: 20px;
  font-style: italic;
  font-weight: 600;
  margin-top: 20px;
}

.texto-secao-nossos-servicos p,
.texto-secao-nossos-clientes p,
.texto-secao-sobre-nos p {
  padding: 0px 100px;
  line-height: 26px;
  margin: 0px 0;
}
 /* botão whatsapp */

.whatsapp {

  position: fixed;
  bottom: 20px;
  right: 10px;
  z-index: 100;
  width: 200px;
  height: 70px;
  border-radius: 50px;
  text-align: center;
  font-size: 1.5em;
  background-color: #34af23;
  background-image: url('/public/assets/whatsapp.webp');
  border: none;
}



@media screen and (max-width: 1024px) {

   .texto-secao-nossos-clientes p,
   .texto-secao-nossos-servicos p,
   .texto-secao-sobre-nos p {
	 padding: 20px 20px
   }
}

@media screen and (max-width: 768px){

  .texto-banner h1 {
    font-size: 50px;
  }

  .secao-nossos-servicos {
    flex-direction: column;
    align-items: center;
  }

  .secao-nossos-servicos img {
    width: 100%;
  }

  .texto-secao-nossos-servicos,
  .texto-secao-nossos-clientes,
  .texto-secao-sobre-nos {
    width: 100%;
    padding: 100px 0px;
    text-align: center;
  }

  .secao-nossos-clientes {
    flex-direction: column-reverse;
    align-items: center;
  }

  .secao-nossos-clientes img {
    width: 100%;
    margin-bottom: 0px;
  }

  .secao-sobre-nos {
    flex-direction: column;
    align-items: center;
  }

  .secao-sobre-nos img {
    width: 100%;
    margin-bottom: 0px;
    
    
  }
}

@media screen and (max-width: 425px){
  .texto-banner h1 {
    font-size: 38px;
  }

  .texto-secao-nossos-clientes h2,
  .texto-secao-nossos-servicos h2,
  .texto-secao-sobre-nos h2 {
	line-height: 40px;
  }

  .secao-nossos-clientes h2  {
    
    margin-top: -80px;
    
  }

  .secao-nossos-clientes img  {
    
    margin-top: -80px;
    
  }

  .secao-sobre-nos {


    margin-bottom: -100px;
  }






  



  
}

