header {

    width: 100%;
    padding: 10px 20px;
    display: flex;
    position: hidden;
    z-index: 1;
    background-color: #000;

}

header div img {

    border-radius: 50px;
   
  }

header div {

    display: flex;
    justify-content: space-between;
    align-items: center;

}

header nav {

    width: 300px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.link-topo {

    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-decoration: none;

}

@media screen and (max-width: 662px) {
    header div {

        flex-direction: column;

    }

    header nav {

        width: 100%;
        justify-content: space-around;
        padding-top: 10px;
    }

}